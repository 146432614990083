import type { CheckboxGroupOption } from '~/models/formidable'
import { useField } from 'vee-validate'

export function useToggleField({
  fieldsetName,
  fieldsetRules,
  fieldsetOptions,
}: {
  fieldsetName: string
  fieldsetRules: string
  fieldsetOptions: CheckboxGroupOption[]
}) {
  const isToggleOn = ref(false)

  const { value: selectedOptions, setValue } = useField(
    fieldsetName,
    fieldsetRules,
    { type: 'checkbox' },
  )

  watch(
    () => selectedOptions.value,
    (options: string[]) => {
      isToggleOn.value = options?.length === fieldsetOptions.length
    },
  )

  const toggleAll = () => {
    isToggleOn.value = !isToggleOn.value

    if (isToggleOn.value) {
      const allOptions = fieldsetOptions.map((option) => option.label)
      setValue(allOptions)
    } else {
      setValue([])
    }
  }

  return {
    toggleAll,
    isToggleOn,
  }
}
