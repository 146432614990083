<template>
  <div
    class="font-lato mt-4 flex w-full flex-row flex-wrap justify-center font-light uppercase"
  >
    <p class="text-center text-[10px]">
      For details on how your data is used and stored, see our &nbsp;
      <NuxtLink to="/privacy" target="_blank" class="text-p-900 p-0">
        Privacy Policy
      </NuxtLink>
    </p>
  </div>
</template>
