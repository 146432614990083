<template>
  <div class="mb-4">
    <div class="input relative w-full" :style="{ '--bg-color': bgColor }">
      <label
        :for="id"
        class="label font-lato text-surface-em-medium absolute ml-3 block transform cursor-text transition-transform"
        :class="labelClasses"
      >
        {{ label }}
      </label>
      <Field
        :id="id"
        ref="field"
        :name="name"
        :rules="rules"
        as="textarea"
        :autocomplete="autocomplete"
        class="input border-s-500 focus:ring-p-500 inline-block h-52 w-full rounded bg-transparent px-4 py-4 outline-none focus:border-transparent focus:ring-2 focus:ring-inset"
        :class="{ 'border-error': hasError }"
        :placeholder="placeholder"
        :label="label"
        :value="props.modelValue"
        @blur="onBlur"
        @focus="focused = true"
        @update:model-value="(value) => $emit('update:modelValue', value)"
      />
    </div>

    <div v-if="hasError" class="h-4">
      <ErrorMessage class="text-xxs text-error block" :name="name" />
    </div>
    <p
      v-else-if="helpText"
      class="font-lato text-surface-em-medium pl-3 text-xs"
    >
      {{ helpText }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { Field, ErrorMessage, useFieldError } from 'vee-validate'
const emit = defineEmits(['update:modelValue', 'blur'])
const props = withDefaults(
  defineProps<{
    name: string
    label: string
    modelValue: string | number
    helpText?: string
    bgColor?: string
    autocomplete?: string
    initialFocus?: boolean
    rules?: string
    placeholder?: string
  }>(),
  {
    helpText: '',
    bgColor: 'white',
    autocomplete: '',
    initialFocus: false,
    rules: '',
    placeholder: '',
  },
)

const { $browser } = useNuxtApp()
const id = useId()
const focused = ref(false)
const field = ref<{ $el: HTMLTextAreaElement } | null>(null)
const error = useFieldError(props.name)

const hasError = computed(() => error.value)

const labelClasses = computed(() => {
  if (focused.value || props.modelValue !== '' || $browser.isApple()) {
    return [
      '-translate-y-2/4',
      'scale-75',
      'origin-top-left',
      'non-empty-field-label',
    ]
  }
  return ['translate-y-3/4']
})

const onBlur = ($event) => {
  emit('blur', $event.target.value)
  focused.value = false
}

watch(
  () => props.initialFocus,
  (focus) => {
    if (!focus) return
    nextTick(() => field.value.$el.focus())
  },
)

onMounted(() => {
  if (!props.initialFocus) return
  nextTick(() => field.value.$el.focus())
})
</script>

<style scoped>
.non-empty-field-label {
  top: 2px;
}

.label::after {
  content: '';
  width: calc(100% + 8px);
  height: 50%;
  position: absolute;
  z-index: -1;
  left: -4px;
  top: 0.7rem;
  background-color: var(--bg-color);
}

.input {
  background-color: var(--bg-color);
}

.input:-webkit-autofill {
  background-clip: text !important;
}

.input:-webkit-autofill:focus {
  background-clip: text !important;
}
</style>
